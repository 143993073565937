import Alpine from 'alpinejs';
import hljs from 'highlight.js/lib/core';
import css from 'highlight.js/lib/languages/css';
import xml from 'highlight.js/lib/languages/xml';

window.Alpine = Alpine;
Alpine.start();

hljs.registerLanguage('xml', xml);
hljs.registerLanguage('css', css);

let code = hljs.highlight('<script src="https://api.checkwx.com/widget?key=YOUR_API_KEY"></script>', {
	language: 'xml'
}).value;
document.querySelector('#block1').innerHTML = code;

code = hljs.highlight('<div data-type="METAR" data-station="KJFK"></div>', { language: 'xml' }).value;
document.querySelector('#block2').innerHTML = code;

let doc = `
<html>
  <head>
    <title>Automatically refresh METAR Example</title>
  </head>

  <body>
    <h3>Los Angeles International Airport</h3>
    <div class="checkwx-container" data-type="METAR" data-station="KLAX"></div>
    
    <p>This metar will automatically refresh every 15 minutes.</p>

    <script src="https://api.checkwx.com/widget?key=YOUR_API_KEY&refresh=15"></script>
  </body>
</html>
`;

code = hljs.highlight(doc, { language: 'xml', ignoreIllegals: 0 }).value;
document.querySelector('#custom1').innerHTML = code;

doc = `
<div data-type="METAR" data-station="KLAX">
  
<!-- The following paragraph tag was automatically inserted by our widget. -->
<p class="checkwx-item">
<a class="checkwx-link" href="https://www.checkwx.com/weather/KLAX/metar" target="_blank">KLAX</a> 
METAR 092153Z 26007KT 10SM CLR 24/01 A3016 RMK AO2 SLP213 T02440011
</p>
<!-- End of widget insert. -->

</div>
`;

code = hljs.highlight(doc, { language: 'xml', ignoreIllegals: 0 }).value;
document.querySelector('#custom2').innerHTML = code;

doc = `
<style>
  .checkwx-item {
    font-size: 20px;
    color: blue;
  }

  .checkwx-link {
    font-weight: bold;
    color: red;
    text-decoration: underline;
  }
</style>
`;

code = hljs.highlight(doc, { language: 'css', ignoreIllegals: 0 }).value;
document.querySelector('#custom3').innerHTML = code;

doc = `
<html>
  <head>
    <title>Single ICAO - METAR Example</title>
  </head>

  <body>
    <h3>Los Angeles International Airport</h3>
    <div class="checkwx-container" data-type="METAR" data-station="KLAX"></div>
    
    <script src="https://api.checkwx.com/widget?key=YOUR_API_KEY"></script>
  </body>
</html>
`;

code = hljs.highlight(doc, { language: 'xml', ignoreIllegals: 0 }).value;
document.querySelector('#example1').innerHTML = code;

doc = `
<html>
  <head>
    <title>Multiple ICAOs - METAR Example</title>
  </head>

  <body>
    <h3>METARS</h3>
    <div class="checkwx-container" data-type="METAR" data-station="KLAX,KBOS,KMIA"></div>
    
    <script src="https://api.checkwx.com/widget?key=YOUR_API_KEY"></script>
  </body>
</html>
`;

code = hljs.highlight(doc, { language: 'xml', ignoreIllegals: 0 }).value;
document.querySelector('#example2').innerHTML = code;

doc = `
<html>
  <head>
    <title>Multiple ICAOs in Multiple Positions - METAR Example</title>
  </head>

  <body>
    <h3>Heathrow Airport</h3>
    <div class="checkwx-container" data-type="METAR" data-station="EGLL"></div>

    <h3>Interesting Facts:</h3>
    <p>Heathrow Airport, called London Airport until 1966 and now known as London Heathrow, is the main international airport serving London, England.</p>
    <p>Frankfurt Airport is a major international airport located in Frankfurt, the fifth-largest city of Germany and one of the world's leading financial centres.</p>

    <h3>Frankfurt Airport</h3>
    <div class="checkwx-container" data-type="METAR" data-station="EDDF"></div>
 
    <script src="https://api.checkwx.com/widget?key=YOUR_API_KEY"></script>
  </body>
</html>
`;

code = hljs.highlight(doc, { language: 'xml', ignoreIllegals: 0 }).value;
document.querySelector('#example3').innerHTML = code;

doc = `
<html>
  <head>
    <title>Single ICAO - TAF Example</title>
  </head>

  <body>
    <h3>Indira Gandhi International Airport</h3>
    <div class="checkwx-container" data-type="METAR" data-station="VIDP"></div>
    
    <script src="https://api.checkwx.com/widget?key=YOUR_API_KEY"></script>
  </body>
</html>
`;

code = hljs.highlight(doc, { language: 'xml', ignoreIllegals: 0 }).value;
document.querySelector('#example4').innerHTML = code;

doc = `
<html>
  <head>
    <title>Multiple ICAOs - TAF Example</title>
  </head>

  <body>
    <h3>Shijiazhuang Zhengding International Airport</h3>
    <div class="checkwx-container" data-type="METAR" data-station="ZBSJ"></div>
    <div class="checkwx-container" data-type="TAF" data-station="ZBSJ"></div>

    <script src="https://api.checkwx.com/widget?key=YOUR_API_KEY"></script>
  </body>
</html>
`;

code = hljs.highlight(doc, { language: 'xml', ignoreIllegals: 0 }).value;
document.querySelector('#example5').innerHTML = code;
